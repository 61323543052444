
.home {
  height: 100%;
}
.main {
  margin-top: 60px;
  min-height: calc(100vh - 60px);
  margin-left: 180px;
}
.aside {
  margin-top: 60px;
  bottom: 0;
  top: 0;
  position: fixed;
}
