
.menu-fixed-position {
  top: 60px;
}
.menu:not(.el-menu--collapse) {
  width: 180px;
}

.menu {
  height: 100%;
  background: #fff;
}
.active-menu {
  background-color: #ebf5ff;
  @apply border-primary-dark border-r-4;
}
